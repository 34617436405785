import { config } from "./config";

let baseConfig

if(config.env == 'DEV') {
    baseConfig = {
        scheme: 'https',
        host: 'admin-api.penpencil.xyz',
    }
}
else {
    baseConfig = {
        scheme: 'https',
        host: 'admin-api.penpencil.xyz',
    }
}

const baseUrl = `${baseConfig.scheme}://${baseConfig.host}`;

const ApiRoutes = {
  upvotePoll: () => {
      return `https://api.penpencil.co/v2/poll/upvote-poll`
  },
}

export default ApiRoutes;