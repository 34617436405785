import { Component } from "react";
import './sidemenu.component.css';
import { Assets } from "../../assets/assets";
import ChatList from "./components/ChatList/chatlist.component";
import Poll from "./components/Poll/poll.component";
import * as mqtt from 'mqtt/dist/mqtt';
import { v4 as uuidv4 } from 'uuid';
import ThreeD from "./components/3D/3d.component";

//mqtt
const connectUrl = "wss://emqx.penpencil.net/mqtt";
const options = {
  //constants 
  url: "wss://emqx.penpencil.net/mqtt",
  clean: true,
  connectTimeout: 10000, //connection timeout in 10sec
  reconnectPeriod: 10000, // will reconnect in 10 sec
  keepalive: 10000,
  password: 'kBasdmdWbQVNfjE444e',
  //variables
  clientId: `mqtt-web-client-${uuidv4()}`,
  username: 'a699890e4e2822765aa594ae1d17ad695c44d8b0dc2fcf467701e934cb202d0b',
}
const client = mqtt.connect(connectUrl, options)


class SideMenu extends Component {
  constructor() {
    super()
    this.state = {
      selectedNav: "Polls",
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="sideMenuWrapper" >
        <div className="sideMenuTopComponent" >
          <div className="pollDoubt3dNavbar">
            <p onClick={() => this.setState({selectedNav: "Polls"})} className={this.state.selectedNav === "Polls" ? "pollDoubt3dNavOptionsActive" : "pollDoubt3dNavOptions"}>Polls</p>
            <p onClick={() => this.setState({selectedNav: "Doubts"})} className={this.state.selectedNav === "Doubts" ? "pollDoubt3dNavOptionsActive" : "pollDoubt3dNavOptions"}>Doubts</p>
            <p onClick={() => this.setState({selectedNav: "3D"})} className={this.state.selectedNav === "3D" ? "pollDoubt3dNavOptionsActive" : "pollDoubt3dNavOptions"}>3D</p>
          </div>
          <div style={{display: this.state.selectedNav === "Polls" ? '' : 'none'}}>
            <Poll pollData={this.state.pollData} isPollAnswered={this.state.isPollAnswered} />
          </div>

          <div className="threeDWrapper" style={{display: this.state.selectedNav === "3D" ? '' : 'none'}}>
            <ThreeD client={client} />
          </div>
        </div>
        <div className="sideMenuBottomComponent" >
          <ChatList />
        </div>
      </div>
    )
  }
}

export default SideMenu;