import './App.css';
import SideMenu from './components/SideMenu/sidemenu.component';
import VideoPlayer from './components/VideoPlayer/videoplayer.component';

function App() {
  return (
    <div className="videoPlayerContainer">
      <VideoPlayer/>
      <SideMenu/>
    </div>
  );
}

export default App;
