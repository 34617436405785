import { Component } from "react";
import './chatlist.component.css';
import { Assets } from "../../../../assets/assets";

class ChatList extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="chatListWrapper" >
        <span className="chatListTopBar" >
          <p className="chatListTitle" >Live Chat</p>
          <p className="chatListSubTitle" >All messages</p>
        </span>

        <span className="chatListMiddleBar" >

        </span>

        <span className="chatListBottomBar" >
          <img className="chatListBottomBarIcon" src={Assets.png.smileIconPng} />
          <input placeholder="Chat here" className="chatInputBox" type="text" />
          <img className="chatListBottomBarIcon sendMessageIconChatList" src={Assets.png.sendMessagePng} />
        </span>
      </div>
    )
  }
}

export default ChatList;