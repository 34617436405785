import { Component } from "react";
import './poll.component.css';
import { Assets } from "../../../../assets/assets";
import axios from "axios";
import ApiRoutes from "../../../../config/api.config";
import Lottie from "react-lottie";
import * as mqtt from 'mqtt/dist/mqtt';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment/moment";
import toast, { Toaster } from 'react-hot-toast';

//mqtt
const connectUrl = "wss://emqx.penpencil.net/mqtt";
const options = {
  //constants 
  url: "wss://emqx.penpencil.net/mqtt",
  clean: true,
  connectTimeout: 10000, //connection timeout in 10sec
  reconnectPeriod: 10000, // will reconnect in 10 sec
  keepalive: 10000,
  password: 'kBasdmdWbQVNfjE444e',
  //variables
  clientId: `mqtt-web-client-${uuidv4()}`,
  username: 'a699890e4e2822765aa594ae1d17ad695c44d8b0dc2fcf467701e934cb202d0b',
}
const client = mqtt.connect(connectUrl, options)


export default class Poll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAnswer: [],
      showSubmittedButtton: false,
      timeRemaining: 0,
      pollData: {},
    }
  }

  upvotePoll(pollId) {
    axios.put("https://api.penpencil.co/v2/poll/upvote-poll",
      { pollId: pollId, optionId: this.state.selectedAnswer, }, {
      headers: { Authorization: `Bearer 42735d69e766c06cd6a62ab3a3b0c234a77d0c381c3457d052e7a1ee5434947d` }
    }).then((response) => {
      // console.log(response);
      this.setState({ showSubmittedButtton: true });
    }).catch((error) => {
      console.log("error at upvote poll", error);
    })
  }

  componentDidMount() {
    client.on('error', (error) => {
      console.log('Connection failed:', error)
    })
    
    client.on('message', (topic, message) => {
      let msg = JSON.parse(message.toString());
      // console.log(msg);
      if (msg.operation === "start") {
        this.setState({pollData: msg});
        this.setTimer(msg.data.expiryDuration)
        if(!msg.pollId) return;
        console.log("Poll start")
        // this.setState({ timeRemaining: msg.data.expiryDuration })
        this.setState({ selectedAnswer: [] })
        this.setState({ showSubmittedButtton: false })
      }
      if (msg.operation === "result") {
        this.setState({pollData: msg});
        JSON.stringify(msg.data?.result.correctOption) === JSON.stringify(this.state.selectedAnswer) 
        ? toast('Your answer was correct!', {
          style: {backgroundColor: '#4caf50', color: '#fff'},
          icon: '🔥',
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        }) 
        : toast("Your answer was wrong!", {
          style: {backgroundColor: '#f44336', color: '#fff'},
          icon: '😭',
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        })
      }
    })
    
    client.subscribe(`schedule/649988c11e2f5c00185e8003/poll`, { qos: 2, rap: true }, (error) => {
      if (error) {
        console.log('Subscribe to poll error', error)
        return
      }
    });
  }

  leaderboardAnimation = {
    loop: true,
    autoplay: true,
    animationData: Assets.lottie.trophy,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  async setTimer(duration) {
    console.log(duration)
    if(duration < 1) return;
    duration = duration - 1
    this.setState({ timeRemaining: duration })
    setTimeout(() => {
      this.setTimer(duration)
    }, 1000)
  }

  render() {
    return (
      <div>
        <Toaster />
        <div style={{ display: this.state.pollData.operation === "start" ? '' : 'none' }}>
          <div className="pollWrapper" style={{ display: this.state.pollData.data?.type === "SINGLE" ? '' : 'none' }}>
            <p onClick={() => this.setState({ selectedAnswer: [1] })} className={this.state.selectedAnswer.includes(1) ? 'pollOptionsActive' : 'pollOptions'}>A</p>
            <p onClick={() => this.setState({ selectedAnswer: [2] })} className={this.state.selectedAnswer.includes(2) ? 'pollOptionsActive' : 'pollOptions'}>B</p>
            <p onClick={() => this.setState({ selectedAnswer: [3] })} className={this.state.selectedAnswer.includes(3) ? 'pollOptionsActive' : 'pollOptions'}>C</p>
            <p onClick={() => this.setState({ selectedAnswer: [4] })} className={this.state.selectedAnswer.includes(4) ? 'pollOptionsActive' : 'pollOptions'}>D</p>
          </div>
          <div className="pollWrapper" style={{ display: this.state.pollData.data?.type === "MULTIPLE" ? '' : 'none' }}>
            <p onClick={() => this.setState({ selectedAnswer: [...this.state.selectedAnswer, 1] })} className={this.state.selectedAnswer.includes(1) ? 'pollOptionsActive' : 'pollOptions'}>A</p>
            <p onClick={() => this.setState({ selectedAnswer: [...this.state.selectedAnswer, 2] })} className={this.state.selectedAnswer.includes(2) ? 'pollOptionsActive' : 'pollOptions'}>B</p>
            <p onClick={() => this.setState({ selectedAnswer: [...this.state.selectedAnswer, 3] })} className={this.state.selectedAnswer.includes(3) ? 'pollOptionsActive' : 'pollOptions'}>C</p>
            <p onClick={() => this.setState({ selectedAnswer: [...this.state.selectedAnswer, 4] })} className={this.state.selectedAnswer.includes(4) ? 'pollOptionsActive' : 'pollOptions'}>D</p>
          </div>
          <div className="pollWrapper" style={{ display: this.state.pollData.data?.type === "INTEGER" ? '' : 'none' }}>
            <input className="integerInputBox" type="number" placeholder="answere here" onChange={e => this.setState({ selectedAnswer: [parseInt(e.target.value)] })} />
          </div>
          <div className="pollWrapper" style={{ display: this.state.pollData.data?.type === "VOTE" ? '' : 'none' }}>
            <p onClick={() => this.setState({ selectedAnswer: [1] })} className={this.state.selectedAnswer.includes(1) ? 'pollOptionsActive' : 'pollOptions'}>Yes</p>
            <p onClick={() => this.setState({ selectedAnswer: [2] })} className={this.state.selectedAnswer.includes(2) ? 'pollOptionsActive' : 'pollOptions'}>No</p>
          </div>
          {/* Time remaining and leaderboard */}
          <div className="timeRemainingAndSubmit">
            <p className="timeRemainingText">Time Remaining: <span className="timeText">{this.state.pollData.data?.expiryDuration === null ? '∞' : this.state.timeRemaining}</span></p>
            {/* Not Selected */}
            <div className="submitButtonInactiveWrapper" style={{ display: !this.state.showSubmittedButtton && this.state.selectedAnswer.length === 0 ? '' : 'none' }}>
              <p onClick={() => alert('Select an optionn first')} className="submitButtonInactive">Submit</p>
            </div>
            {/* Submit */}
            <div className="submitButtonWrapper" style={{ display: !this.state.showSubmittedButtton && this.state.selectedAnswer.length !== 0 ? '' : 'none' }}>
              <p onClick={() => this.upvotePoll(this.state.pollData.pollId)} className="submitButton">Submit</p>
            </div>
            {/* Submitted */}
            <div className="submitButtonWrapper" style={{ display: this.state.showSubmittedButtton ? '' : 'none' }}>
              <p className="submittedButton">Submitted</p>
            </div>
          </div>
        </div>
        {/* No Active Poll */}
        <div className="noPollImageWrapper" style={{ display: this.state.pollData.operation === "start" || this.state.pollData.operation === "stop_expiry" || this.state.pollData.operation === "result" ? 'none' : '' }}>
          <img src={Assets.png.noPoll} alt="no-poll.png" className="noPollImage" />
          <p>No active poll</p>
        </div>
        {/* Waiting for results */}
        <div className="noPollImageWrapper" style={{ display: this.state.pollData.operation === "stop_expiry" ? '' : 'none' }}>
          <Lottie options={this.leaderboardAnimation} height={100} width={100} />
          <p>Waiting for Leaderboard!</p>
        </div>
        {/* Results */}
        <div style={{ display: this.state.pollData.operation === "result" ? '' : 'none' }}>
          {
            this.state.pollData.operation === "result" &&
            <div className="leaderboardHeaderWrapper">
              <p className="leaderboardHeaderText">Rank</p>
              <p className="leaderboardHeaderText">Name</p>
              <p className="leaderboardHeaderText">Time taken</p>
            </div>
          }
          {
            this.state.pollData.operation === "result" &&
            this.state.pollData.data?.leaderboard.slice(0, 5).map((item, index) => {
              return (
                <div>
                <div className={item.rank === 1 ? "leaderboardTopperWrapper" : "leaderboardWrapper"}>
                  <p className={item.rank === 1 ? "leaderboardTopperText" : "leaderboardText"}>{"#" + item.rank}</p>
                  <p className={item.rank === 1 ? "leaderboardTopperText" : "leaderboardText"}>{item.createdBy.firstName + " " + item.createdBy.lastName}</p>
                  <p className={item.rank === 1 ? "leaderboardTopperText" : "leaderboardText"}>{moment.duration(item.timeTaken).asSeconds() + "s"}</p>
                </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}