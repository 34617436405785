import liveIconSvg from './svg/live_icon.svg';
import smileIconSvg from './svg/smile_emoji.svg'
import sendMessageSvg from './svg/send_message.svg'

import handRaiseDisabledPng from './png/raise-hand-disabled.png'
import handRaiseEnabledPng from './png/raise-hand-enabled.png'
import videoCameraEnabledPng from './png/video-camera-enabled.png'
import videoCameraDisabledPng from './png/video-camera-disabled.png'
import smileIconPng from './png/smile-emoji-grey.png'
import sendMessagePng from './png/send-message.png'
import pwIcon from './png/icon.png'
import noPoll from './png/no-poll.png'
import trophy from './lottiefiles/trophy.json'

export const Assets = {
    svg: {
        liveIcon: liveIconSvg,
        smileIconSvg,
        sendMessageSvg
    },
    png: {
        handRaiseDisabledPng,
        handRaiseEnabledPng,
        videoCameraEnabledPng,
        videoCameraDisabledPng,
        smileIconPng,
        sendMessagePng,
        pwIcon,
        noPoll
    },
    lottie: {
        trophy
    }
}