import { Component } from "react";
import './videoplayer.component.css';
import * as OvenPlayer from 'ovenplayer';
import Hls from 'hls.js';
import { Assets } from "../../assets/assets";
import OvenLiveKit from 'ovenlivekit'

window.Hls = Hls

class VideoPlayer extends Component {
    constructor() {
        super()
        this.state = {
            isWebRtc: false
        }
        this.handRaiseHandle = this.handRaiseHandle.bind(this)
    }

    generateOvenPlayerConfig(source, type) {
        return {
            "autoStart": true,
            "autoFallback": true,
            "mute": false,
            "sources": [source],
            controls: type == "webrtc" ? false : true,
            waterMark: {
                image: Assets.png.pwIcon,
                position: 'top-right',
                y: '10px',
                x: '10px',
                width: '40px',
                height: '40px',
                opacity: 0.7
            },
            showSeekControl: true,
            timecode: true,
            playbackRates: [2, 1.75, 1.5, 1.25, 1],
            currentProtocolOnly: true,
            hlsConfig: {
                "liveSyncDuration": 1,
                "liveMaxLatencyDuration": 4,
                "maxLiveSyncPlaybackRate": 1
            }
        }
    }

    componentDidMount() {
        this.player = OvenPlayer.create("player_id", this.generateOvenPlayerConfig({
            "type": "ll-hls",
            "file": "https://d13w3ra0npnbls.cloudfront.net/app/stream/index.m3u8"
        }, "hls"));
        this.player.play();
        this.player.on('ready', (e) => {
            this.player.seek(Number.MAX_SAFE_INTEGER)
        })
    }

    handRaiseHandle() {
        if(this.state.isWebRtc) {
            this.player = OvenPlayer.create("player_id", this.generateOvenPlayerConfig({
                "type": "ll-hls",
                "file": "https://d13w3ra0npnbls.cloudfront.net/app/stream/index.m3u8"
            }, "hls"));
            this.setState({ isWebRtc: false })
            this.player.play();
            this.player.on('ready', (e) => {
                this.player.seek(Number.MAX_SAFE_INTEGER)
            })
        }
        else {
            this.player = OvenPlayer.create("player_id", this.generateOvenPlayerConfig({
                "type": "webrtc",
                "file": "wss://webrtc-socket.betterpw.live/app/stream"
            }, "webrtc"));
            this.setState({ isWebRtc: true })
            this.player.play();
            this.player.on('ready', (e) => {
                this.player.seek(Number.MAX_SAFE_INTEGER)
            })
            const ovenLivekit = OvenLiveKit.create();
            // Get media stream from user device
            ovenLivekit.getUserMedia().then(function () {
                // Got device stream and start streaming to OvenMediaEngine
                ovenLivekit.startStreaming('wss://webrtc-socket.betterpw.live/app_webrtc/pankaj?direction=send');
            });
        }
    }

    render() {
        return(
            <div className="videoPlayerWrapper" >
                <div className="videoPlayerTopBar">

                </div>

                <div className="videoPlayerMiddleBar">
                    <div id="player_id" className="videoPlayer" />
                </div>
                
                <div className="videoPlayerBottomBar">
                    <span className="left" >
                        <img src={Assets.svg.liveIcon} className="liveIconVideoPlayer" />
                    </span>

                    <span className="middle" >
                        <img className="videoPlayerMiddleBottomIcons" onClick={this.handRaiseHandle} src={Assets.png.handRaiseDisabledPng} />
                        <img className="videoPlayerMiddleBottomIcons" src={Assets.png.videoCameraDisabledPng} />
                        <img className="videoPlayerMiddleBottomIcons" src={Assets.png.handRaiseDisabledPng} />
                    </span>
                </div>
            </div>
        )
    }
}

export default VideoPlayer;