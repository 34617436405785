import React, { Component, useEffect, useRef, useState, Suspense } from 'react'
import * as mqtt from 'mqtt/dist/mqtt';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Bounds, OrbitControls, PerformanceMonitor, Stage, useAnimations } from "@react-three/drei";
import { Canvas } from '@react-three/fiber';
import './3d.component.css'

const connectUrl = "wss://emqx.penpencil.net/mqtt";
const options = {
  //constants 
  url: "wss://emqx.penpencil.net/mqtt",
  clean: true,
  connectTimeout: 10000, //connection timeout in 10sec
  reconnectPeriod: 10000, // will reconnect in 10 sec
  keepalive: 10000,
  password: 'kBasdmdWbQVNfjE444e',
  //variables
  clientId: `mqtt-web-client-${uuidv4()}`,
  username: 'a699890e4e2822765aa594ae1d17ad695c44d8b0dc2fcf467701e934cb202d0b',
}
const client = mqtt.connect(connectUrl, options)

export default function ThreeD() {
  const [modelUri, setModelUri] = useState("https://d2bps9p1kiy4ka.cloudfront.net/5eb393ee95fab7468a79d189/75e7d8b5-5b3e-4918-aead-d1a30bc2c5b3.glb");
  
  useEffect(() => {
    client.on('error', (error) => {
      console.log('Connection failed:', error)
    })
    
    client.on('message', (topic, message) => {
      let msg = JSON.parse(message.toString());
      console.log(msg);
      if (msg.operation === "create") {
        setModelUri(msg.model.url)
      }
    })
    
    client.subscribe(`schedule/649988c11e2f5c00185e8003/three_d_model`, { qos: 2, rap: true }, (error) => {
      if (error) {
        console.log('Subscribe to poll error', error)
        return
      }
    });
  })

  function Model() {
    const group = useRef();
    const { scene, animations } = useLoader(GLTFLoader, modelUri);
    const { actions, mixer } = useAnimations(animations, group);
    useEffect(() => {
        actions.Animation?.play();
    }, [mixer]);
    return <primitive ref={group} object={scene} dispose={null} />;
  }

  return(
    <div className='canvasWrapper' >
      <Canvas style={{ 'height': '100%' }} camera={{ fov: 10 }}  >
        <PerformanceMonitor >
            <pointLight color="white" intensity={1} position={[10, 10, 10]} />
            <Suspense fallback={null}>
                <Stage adjustCamera={false} intensity={0.6} shadows={false} >
                    <Bounds fit clip>
                        <Model />
                    </Bounds>
                </Stage>
            </Suspense>
        </PerformanceMonitor>
        <OrbitControls />
    </Canvas>
    </div>
  )
}
// export default class ThreeD extends Component {

//   constructor() {
//     super()
//     this.state = {
//       model: ""
//     }
//   }

//   componentDidMount() {
//     client.on('error', (error) => {
//       console.log('Connection failed:', error)
//     })
    
//     client.on('message', (topic, message) => {
//       let msg = JSON.parse(message.toString());
//       // console.log(msg);
//       this.setState({pollData: msg});
//       if (msg.operation === "create") {
//         this.setState({ model: msg.model.url })
//       }
//     })
    
//     client.subscribe(`schedule/649988c11e2f5c00185e8003/three_d_model`, { qos: 2, rap: true }, (error) => {
//       if (error) {
//         console.log('Subscribe to poll error', error)
//         return
//       }
//     });
//   }


//   model() {
//     const group = useRef();
//     const { scene, animations } = useLoader(GLTFLoader, this.state.model);
//     const { actions, mixer } = useAnimations(animations, group);
//     useEffect(() => {
//         actions.Animation?.play();
//     }, [mixer]);
//     setModelLoaded(true)
//     return <primitive ref={group} object={scene} dispose={null} />;
//   }

//   render() {
//     return (
//       <div>
//         <Canvas id={modelUri} dpr={dpr} style={{ 'height': '100%' }} camera={{ fov: 10 }}  >
//           <PerformanceMonitor onIncline={() => setDpr(1.3)} onDecline={() => setDpr(0.8)} >
//               <pointLight color="white" intensity={1} position={[10, 10, 10]} />
//               <Suspense fallback={null}>
//                   <Stage adjustCamera={false} intensity={0.6} shadows={false} >
//                       <Bounds fit clip>
//                           { this.model() }
//                       </Bounds>
//                   </Stage>
//               </Suspense>
//           </PerformanceMonitor>
//           <OrbitControls />
//       </Canvas>
//       </div>
//     )
//   }
// }
